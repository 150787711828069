export * from "./queries/print.js";
export * from "./queries/layout.js";
export * from "./queries/records.js";
export * from "./queries/user.js";

export const GET_DISCOGS_DATA = `
query (
  $id: Int,
) {
  getDiscogsData(input:$id) {
		id
  }
}`;

export const GET_DISCOGS_ID = `query {
  records(
    limit: 99999
    query: {
      discogsId_gt: 0
    }) {
    discogsId
  }
}`;
