<template>
  <div>
    <div class="m-4">
      <b-card-group deck>
        <b-card class="overflow-hidden" header="1. Add Records" header-tag="h3" title="Fast accurate title strips">
          <b-card-text>
            <p>
              Get started here adding records you'd like to make title strips
              for.
            </p>
          </b-card-text>
          <template #footer>
            <b-button :to="{ name: 'add' }" variant="primary">
              Add Record
            </b-button>
          </template>
        </b-card>

        <b-card header="2. Manage My Collection" header-tag="h3" title="Browse your collection">
          <b-card-text>
            <p>Browse and edit the records you've already added.</p>
          </b-card-text>
          <template #footer>
            <b-button :to="{ name: 'collection' }" variant="primary">
              My Collection
            </b-button>
          </template>
        </b-card>
        <b-card header="3. Print Title Strips" header-tag="h3" title="Generate a PDF of Title strips">
          <b-card-text>
            <p>Print the records marked for print.</p>
          </b-card-text>
          <template #footer>
            <b-button :to="{ name: 'print' }" variant="primary">
              Print Shop
            </b-button>
          </template>
        </b-card>
        <b-card header="4. Layout Studio" header-tag="h3" title="Plan your Jukebox layout">
          <b-card-text>
            <p>
              Drag your title strips form your collection onto a virtual
              jukebox; so you can plan the placemnt of your records.
            </p>
          </b-card-text>
          <template #footer>
            <b-button :to="{ name: 'designer' }" variant="primary">
              Layout Studio
            </b-button>
          </template>
        </b-card>
      </b-card-group>
    </div>
  </div>
</template>

<script>
export default {
  name: "landing",
};
</script>

<style lang="scss" scoped>
@import "../styles.scss";
/* Import the Sass file where $accent is defined */

.hero {
  background-image: url("/images/hero.png");
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 400px;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.9);
}
</style>
