import Home from "@/views/Home";
import VueRouter from "vue-router";
import store from "../store"; // Import your store instance

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { title: "jukebox.tools" },
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/pages/About.vue"),
    meta: { title: "About" },
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("../views/pages/Terms.vue"),
    meta: { title: "Terms" },
  },
  {
    path: "/features",
    name: "features",
    component: () => import("../views/pages/Features.vue"),
    meta: { title: "Features" },
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("../views/pages/Privacy.vue"),
    meta: { title: "Privacy" },
  },
  {
    path: "/jbt/add",
    name: "add",
    component: () => import("../views/Add.vue"),
    meta: { title: "Add Record", requiresAuth: true },
  },
  {
    path: "/jbt/layout",
    name: "designer",
    component: () => import("../views/LayoutStudioTable.vue"),
    meta: { title: "Layout Studio Table", requiresAuth: true },
  },
  {
    path: "/jbt/layout/:id",
    name: "designerlayout",
    component: () => import("../views/LayoutStudio.vue"),
    meta: { title: "Layout Studio", requiresAuth: true },
  },
  {
    path: "/jbt/print",
    name: "print",
    component: () => import("../views/Print.vue"),
    meta: {
      title: `Print Sets`,
      requiresAuth: true,
    },
  },
  {
    path: "/jbt/print/:id",
    name: "printSet",
    component: () => import("../views/PrintSet.vue"),
    meta: {
      title: `Print - ${new Date().toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      })}`,
      requiresAuth: true,
    },
  },
  {
    path: "/jbt/collection",
    name: "collection",
    component: () => import("../views/Collection.vue"),
    meta: { title: "My Collection List", requiresAuth: true },
  },
  {
    path: "/jbt/profile",
    name: "profile",
    component: () => import("../views/Profile.vue"),
    meta: { title: "Profile", requiresAuth: true },
  },
  { path: '/:pathMatch(.*)*', name: 'NotFound', 
  component: () => import("../views/404.vue"), },
];

const router = new VueRouter({
  // history: createWebHistory(process.env.BASE_URL),
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  if (to.meta && to.meta.title) {
    document.title = `Jukebox Tools - ${to.meta.title}`;
  } else {
    document.title = "Jukebox Tools ";
  }

  if (!store.getters.getUser && to.meta.requiresAuth) {
    next({ name: "home" });
  } else {
    next();
  }
});

export default router;
