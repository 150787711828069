export const UPDATE_PROFILE = `
mutation ($id: String, $discogsUsername: String, $discogsToken: String) {
  updateOneUser(
    query: {userId: $id}
    set: {discogsUsername: $discogsUsername, discogsToken: $discogsToken}
  ) {
    _id
  }
}`;

export const UPDATE_USER_DEFAULT_TITLE_STRIPS = `
mutation ($id: String, $primaryColor: String, $secondaryColor: String, $tertiaryColor: String, $design: Int) {
  updateOneUser(
    query: { userId: $id }
    set: {
      defaultTitleStrip :{
        primaryColor: $primaryColor
        secondaryColor: $secondaryColor
        tertiaryColor :$tertiaryColor
        design: $design
      }    
    }
  ) {
    _id
  }
}`;

