export const GET_PRINTS = `query {
    prints(    
      sortBy: CREATED_ON_DESC
    ) {
      _id
      title
      page 
      printCount
      status
      updated_on
      created_on 
    }
  }`;
export const GET_PRINTSET = `query($id: ObjectId) {
    printData(          
    query: {
        print_id:  {_id: $id}
      }   
    ) {
      _id,
      titleStrip {
        design
        primaryColor
        secondaryColor
        tertiaryColor
      }
      record_id {
        _id
        speed
        artist
        side {
          a {
            title
          }
          b {
            title
          }
        }
      }
    }
  }`;

export const ADD_PRINTSET_RECORDS = `
mutation ($data: [PrintDatumInsertInput!]!) {
    insertManyPrintData(
      data:$data
    )
    {
     insertedIds
    }
  }`;

export const UPDATE_PRINTSET = `
  mutation ($id: ObjectId, 
    $title: String,
     $status: String, 
     $page: String,
     $printCount: Int,
     $currentDate: DateTime) {
    updateOnePrint(
      query: {_id: $id}
      set: {
        title: $title, 
        status: $status, 
        page: $page, 
        printCount: $printCount, 
        updated_on: $currentDate}
    ) {
      _id
    }
  }`;

export const DELETE_PRINTSET = `
  mutation (
         $id: ObjectId,
  ) {
      deleteOnePrint(
        query:{
          _id: $id
        }
    ) {
      _id
    }
  }`;

export const DELETE_PRINTSET_DATA = `
  mutation (
    $id: ObjectId,
) {
deleteManyPrintData(
   query:{     
     print_id:  {_id: $id}
   }
) {
 deletedCount
}
}`;

export const REMOVE_PRINTSET_RECORD = `
  mutation ($printDataId: ObjectId) {
    deleteOnePrintDatum(
      query: { 
        _id: $printDataId
      }
    ) {
      _id
    }
  }`;

export const ADD_PRINTSET = `
         mutation(
           $userId: ObjectId
           $title: String
           $page: String,
           $printCount: Int,
           $currentDate: DateTime
         ) {
           insertOnePrint(
             data: {
               owner_id: { link: $userId}
               title:  $title     
               page:  $page     
               printCount:  $printCount     
               status: "OPEN"
               created_on: $currentDate
               updated_on: $currentDate
             }
           ) {
             _id
           }
         }
       `;
