import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import * as Realm from "realm-web";
import { RECORD_TOTALCOUNT, GET_LAYOUTS, UPDATE_USER_DEFAULT_TITLE_STRIPS, GET_PRINTS } from "@/queries";
import { APP_ID } from "@/consts";
import makeRequest from "@/requests/makeRequest";
Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

const getDefaultState = () => {
  return {
    user: null,
    recordCount: null,
    layouts: [],
  };
};
const state = getDefaultState();

const loginUser = async (credentials) => {
  const user = await realmApp.logIn(credentials);
  const defaultTitleStrip = user.customData.defaultTitleStrip || {};

  return {
    id: user.id,
    accessToken: user.accessToken,
    email: user.customData.email,
    meta: {
      name: user.customData.name,
      picture: user.customData.picture,
      discogsUsername: user.customData.discogsUsername,
      discogsToken: user.customData.discogsToken,
      defaultTitleStrip: {
        primaryColor: defaultTitleStrip.primaryColor || '#E62222',
        secondaryColor: defaultTitleStrip.secondaryColor || '#FFFFFF',
        tertiaryColor: defaultTitleStrip.tertiaryColor || '#FFFFFF',
        design: defaultTitleStrip.design
          ? Number(defaultTitleStrip.design.$numberInt)
          : 1,
      },
    },
  };
};

export const realmApp = new Realm.App({ id: APP_ID });
export default new Vuex.Store({
  state,
  mutations: {
    /// sync
    setUser(state, payload) {
      state.user = payload;
    },
    setLayouts(state, payload) {
      state.layouts = payload;
    },
    setPrints(state, payload) {
      state.prints = payload;
    },
    updateDefaultTitleStrips(state, payload) {
      state.user.meta.defaultTitleStrip = payload;
    },

    setUserMeta(state, payload) {
      delete payload.userId;
      state.user.meta = payload;
    },
    resetUser(state) {
      state.user = null;
    },
    setRecordCount(state, payload) {
      state.recordCount = payload;
    },
    logout(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    async loginUserPassword(state, data) {
      try {
        const credentials = Realm.Credentials.emailPassword(data.email, data.password);
        const user = await loginUser(credentials);
        state.commit('setUser', user);
      } catch (error) {
        console.error('Login failed:', error);
      }
    },

    async loginCustom(state, token) {
      try {
        const credentials = Realm.Credentials.function({ token });
        const user = await loginUser(credentials);
        state.commit('setUser', user);
      } catch (error) {
        console.error('Login failed:', error);
      }
    },

    async logout(state) {
      state.commit("logout");
    },
    async createAccount(state, data) {
      return await realmApp.emailPasswordAuth.registerUser({
        email: data.email,
        password: data.password,
      }).then(() => {
        state.dispatch('loginUserPassword', { email: data.email, password: data.password })
      });
    },
    async updateUserMeta(state, data) {
      state.commit("setUserMeta", data);
    },

    async updateDefaultTitleStrip(state, data) {
      const request = {
        userId: state.getters.getUser.id,
        ...data,
      };
      makeRequest(UPDATE_USER_DEFAULT_TITLE_STRIPS, request).then((response) => {
        if (response.data.data.updateOneUser) {
          state.commit("updateDefaultTitleStrips", data);
        }
      });
    },
    async getLayouts(state) {
      //  if (state.getters.getLayouts.length === 0) {
      makeRequest(GET_LAYOUTS).then((response) => {
        const data = response.data.data.layouts
        state.commit("setLayouts", data);
      });
      //  }
    },
    async getPrints(state) {
      //  if (state.getters.getLayouts.length === 0) {
      makeRequest(GET_PRINTS).then((response) => {
        const data = response.data.data.prints
        state.commit("setPrints", data);
      });
      //  }
    },

    async getRecordCount(state, force = false) {
      if (!state.getters.getRecordCount || force) {
        makeRequest(RECORD_TOTALCOUNT, { limit: 1 }).then((response) => {
          const count = response.data.data.recordSearch.length
            ? response.data.data.recordSearch[0].totalCount
            : 0;
          state.commit("setRecordCount", count);
        });
      }
    },
  },
  getters: {
    getToken(state) {
      return state.user && state.user.accessToken
        ? state.user.accessToken
        : null;
    },
    getUser(state) {
      return state.user && state.user ? state.user : null;
    },
    getRecordCount(state) {
      return state.recordCount;
    },
    getLayouts(state) {
      return state.layouts;
    },
    getPrints(state) {
      return state.prints;
    },
  },

  plugins: [vuexLocal.plugin],
});
