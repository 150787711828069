export const GET_LAYOUTS = `query {
    layouts(    
      sortBy: CREATED_ON_DESC
    ) {
      _id
      title
      panels
      color
      colsPerPanel
      recordsPerColumn
      headings
      titleStrip {
        primaryColor
        secondaryColor
        tertiaryColor
        design
      }
      updated_on
      created_on 
    }
  }`;

export const GET_LAYOUT = `query($id: ObjectId) {
    layout( 
      query: {
        _id: $id
      }   
    ) {
      _id
      title
      color
      titleStrip {
        primaryColor
        secondaryColor
        tertiaryColor
        design
      }
      panels
      colsPerPanel    
      recordsPerColumn    
      headings
      created_on 
    }
  }`;
export const GET_LAYOUT_RECORDS = `
query ($id: ObjectId) {
  layoutRecords( 
      query: {
        layout:{ _id : $id}
      }   
    ) {
    record {
            _id
        artist
        year
        side {
          a {
            title
          }
          b {
            title
          }
        }
    }
    position
  }
}`;

export const ADD_LAYOUT = `
         mutation(
           $userId: ObjectId
           $title: String
           $color: String
           $primaryColor: String
           $secondaryColor: String
           $tertiaryColor: String
           $design: Int
           $headings: [String]
           $panels: Int
           $colsPerPanel: Int
           $recordsPerColumn: Int
           $currentDate: DateTime
         ) {
           insertOneLayout(
             data: {
               owner_id: { link: $userId}
               title:  $title
               color:  $color
               panels: $panels
               titleStrip: {
                primaryColor: $primaryColor
                secondaryColor: $secondaryColor
                tertiaryColor: $tertiaryColor
                design: $design
              }
               headings: $headings
               colsPerPanel: $colsPerPanel
               recordsPerColumn: $recordsPerColumn
               created_on: $currentDate
               updated_on: $currentDate
             }
           ) {
             _id
           }
         }
       `;

export const UPDATE_LAYOUT = `
         mutation(
           $id: ObjectId,
           $title: String
           $color: String
           $headings: [String]
           $panels: Int
           $primaryColor: String
           $secondaryColor: String
           $tertiaryColor: String
           $design: Int
           $colsPerPanel: Int
           $recordsPerColumn: Int
           $currentDate: DateTime
         ) {
          updateOneLayout(
            query: {_id: $id}
            set: {
               title:  $title
               color:  $color       
               titleStrip: {
                primaryColor: $primaryColor
                secondaryColor: $secondaryColor
                tertiaryColor: $tertiaryColor
                design: $design
              }
               panels: $panels
               headings: $headings
               colsPerPanel: $colsPerPanel
               recordsPerColumn: $recordsPerColumn
               updated_on: $currentDate
             }
           ) {
             _id
           }
         }
       `;


export const CLONE_LAYOUT = `
mutation ($input: String) {
  duplicateLayout(input: $input) {
    id
  }
}`;

export const UPDATE_LAYOUT_RECORDS = `
mutation($records:[LayoutRecordInsertInput!]!) {
  insertManyLayoutRecords(
    data: $records
  )
  {
    insertedIds
  }
}`;

export const DELETE_LAYOUT_RECORDS = `
mutation (
  $id: ObjectId,
) {
  deleteManyLayoutRecords(
 query:{     
   layout:  {_id: $id}
 }
) {
deletedCount
}
}`;

export const DELETE_LAYOUT = `
mutation (
       $id: ObjectId,
) {
    deleteOneLayout(
      query:{
        _id: $id
      }
  ) {
    _id
  }
}`;


