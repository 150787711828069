import axios from "axios";
import store from "@/store/index";
import router from '@/router';
import { URL } from "@/consts";


const buildHeaders = () => {
    return {
        method: "post",
        url: URL,
        headers: {
            Authorization: `Bearer ${store.getters.getToken}`,
        },
    };
};


const makeRequest = (query, variables) =>
    axios({
        ...buildHeaders(),
        data: {
            query,
            variables,
        },
    }).catch((e) => {
        if (e.response.status === 401) {
            store.commit("resetUser");
            router.push({ name: "home" });
        }
    });

export default makeRequest;