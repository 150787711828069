<template>
  <b-navbar toggleable="lg" type="dark" variant="primary" class="d-print-none">
    <b-navbar-brand :to="{ name: 'home' }" class="p-2">
      <img src="/jukebox-tools-wordmark.svg" class="logo" alt="Jukebox Tools logo" aria-label="Jukebox Tools logo" />
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav v-if="token">
        <b-nav-item :to="{ name: 'add' }">Add Record</b-nav-item>
        <b-nav-item :to="{ name: 'collection' }">My Collection</b-nav-item>
        <b-nav-item :to="{ name: 'print' }">Print Shop</b-nav-item>
        <b-nav-item :to="{ name: 'designer' }">Layout Studio</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto" v-if="!token">
        <GoogleLogin />
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto" v-if="token">
        <b-nav-item-dropdown right>
          <template #button-content>
            <img v-if="user.meta.picture && typeof user.meta.picture !== 'object'" :src="user.meta.picture"
              class="profile mr-2" />
            <img v-else src="/images/user-icon.svg" class="profile mr-2" />
            {{ user.meta.name }}
          </template>
          <b-dropdown-item :to="{ name: 'profile' }">Profile</b-dropdown-item>
          <b-dropdown-item @click="logout()">Sign Out</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
// import { useRouter } from 'vue-router';
import GoogleLogin from "@/components/GoogleLogin";

export default {
  name: "NavBarComponent",
  computed: {
    token() {
      return this.$store.getters.getToken;
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push({ name: "home" });
      });
    },
  },
  components: {
    GoogleLogin,
  },
};
</script>
<style scoped>
img.logo {
  width: 130px;
}

img.profile {
  width: 32px;
  border-radius: 8px;
}
</style>
