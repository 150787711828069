export const SPEED_45 = 45;
export const SPEED_78 = 78;

export const APP_ID = "jukebox-zucij";
export const URL = "https://us-east-1.aws.realm.mongodb.com/api/client/v2.0/app/jukebox-zucij/graphql";

export const PAGE_SIZES = [
    { value: "letter", text: "Letter (8.5 x 11 inches)", shortName: "Letter", width: 8.5, height: 11 },
    { value: "legal", text: "Legal (8.5 x 14 inches)", shortName: "Legal", width: 8.5, height: 14 },
    { value: "tabloid", text: "Tabloid (11 x 17 inches)", shortName: "Tabloid", width: 11, height: 17 },
];