import Vue from "vue";
import VueRouter from "vue-router";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vuelidate from 'vuelidate'

import BootstrapVue from "bootstrap-vue/dist/bootstrap-vue.esm";
import "./styles.scss";
Vue.use(BootstrapVue);
Vue.use(VueRouter);
Vue.use(Vuelidate)

Vue.config.productionTip = false;


new Vue({
  store,
  router,
  render: h => h(App)
}).$mount("#app");
 