<template>
  <footer class="d-print-none">
    <b-container class="text-center p-5">
      <b-row>
        <b-col>
          <b-link :to="{ name: 'home' }">
            <img src="/jukebox-tools-wordmark.svg" class="logo" alt="Jukebox tools logo"
              aria-label="Jukebox tools logo" /></b-link>
          <div>
            <b-nav align="center">
              <b-nav-item :to="{ name: 'features' }">Features</b-nav-item>
              <b-nav-item :to="{ name: 'about' }">About</b-nav-item>
              <b-nav-item :to="{ name: 'privacy' }">Privacy</b-nav-item>
              <b-nav-item :to="{ name: 'terms' }">Terms</b-nav-item>
            </b-nav>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>
<style lang="scss" scoped>
@import "../styles.scss";
/* Import the Sass file where $accent is defined */

footer {
  margin-top: 90px;
  background-color: #000;
  color: #fff;
}

a {
  font-family: "Quicksand", sans-serif;
  color: #fff !important;
}

img.logo {
  width: 130px;
}
</style>
