<template>
  <div class="mr-4">
    <div id="g_id_onload" data-client_id="937821616606-deo4uqmol0a4cq35u048063o1clrmd9b.apps.googleusercontent.com"
      data-auto_prompt="false"></div>
  </div>
</template>

<script>
export default {
  name: "googlelogin",
  mounted: function () {
    // eslint-disable-next-line no-undef
    google.accounts.id.initialize({
      client_id:
        "937821616606-deo4uqmol0a4cq35u048063o1clrmd9b.apps.googleusercontent.com",
      callback: (response) => {
        this.$store
          .dispatch("loginCustom", response.credential)
          .then(() => {
            this.$router.push({ name: "home" });
          })
          .catch((e) => {
            this.errorCode = e.errorCode;
          });
      },
    });
    // eslint-disable-next-line no-undef
    google.accounts.id.renderButton(document.getElementById("g_id_onload"), {
      theme: "outline",
      size: "large",
      text: "continue_with",
      shape: "pill",
    });
  },
};
</script>
