export const ADD_RECORD = `
         mutation(
           $userId: ObjectId
           $artist: String
           $speed: Int!
           $year: Int
           $discogsId: Int
           $acquired: DateTime
           $sideATitle: String!
           $sideBTitle: String!
           $currentDate: DateTime!
         ) {
           insertOneRecord(
             data: {
               owner_id: {link:$userId}
               artist: $artist
               speed: $speed
               year: $year
               discogsId: $discogsId
               acquired: $acquired
               createdAt: $currentDate
               updatedAt: $currentDate
               side: { a: { title: $sideATitle }, b: { title: $sideBTitle } }
             }
           ) {
             _id
           }
         }
       `;

export const UPDATE_RECORD = `
mutation (       
  $id: ObjectId,
  $artist: String,  
  $year: Int,
  $speed: Int,
  $discogsId: Int, 
  $sideATitle: String!, 
  $sideBTitle: String!,
  $acquired: DateTime,
  $currentDate: DateTime!) {
  updateOneRecord(
    query: {_id: $id}
    set: {    
      artist: $artist, 
      year: $year, 
      speed: $speed, 
      acquired: $acquired,
      discogsId: $discogsId, 
      updatedAt: $currentDate, 
      side: {
        a: {
          title: $sideATitle},
        b: {
          title: $sideBTitle}
        }
      }
  ) {
    _id
  }
}
`;
export const GET_ALL45 = `query {
    records(query: {speed: 45, 
      deleted_ne:true}, limit: 2000, sortBy:ARTIST_ASC) {
      _id
      artist
      year
      discogs {
        country
        genres
      }
      side {
        a {
          title
        }
      }
      side {
        b {
          title
        }
      }
    }
  }`;

export const GET_RECORD = `query($id: ObjectId) {
    record(
      query: {
        _id: $id
      }) {
       _id
      artist
      acquired
      discogsId
      speed
      year
      side {
        a {
          title
        }
      }
      side {
        b {
          title
        }
      }
    }
  }`;


export const DELETE_RECORD = `
  mutation ($id: ObjectId) {
    updateOneRecord(
      query: {_id: $id}
      set: {    
        deleted: true       
      }
    ) {
      _id
    }
  }`;

export const RECORD_TOTALCOUNT = `query {
  recordSearch(
    input: {
      limit: 1
    }) {      
      totalCount      
  }
}`;

export const RECORD_SEARCH = `query(
  $limit: Int
  $offset: Int
  $query: String
  $sortBy: String
  $sortDir: Int
) {
  recordSearch(
    input: {
      limit: $limit
      offset: $offset
      query: $query
      sortBy: $sortBy
      sortDir: $sortDir
    }) {
      _id
      artist
      discogsId 
      speed
      createdAt
      year
      side {
        a {
          title
        }
      }
      side {
        b {
          title
        }
      } 
      totalCount      
  }
}`;
