<template>
  <div>
    <Home v-if="!token" />
    <Landing v-if="token" />
  </div>
</template>

<script>
import Home from "@/components/Home";
import Landing from "@/components/Landing";

export default {
  name: "home",
  computed: {
    token() {
      return this.$store.getters.getToken;
    },
  },
  components: {
    Home,
    Landing,
  },
};
</script>


<style lang="scss" scoped></style>
