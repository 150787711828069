<template>
  <div id="app">
    <NavBar />
    <b-container fluid class="b-container">
      <router-view></router-view>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";

export default {
  name: "App",
  components: {
    NavBar,
    Footer
  },
};
</script>
<style scoped>
#app {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.b-container {
  flex: 1;
}
</style>
