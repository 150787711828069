<template>
  <div>
    <div class="hero text-center py-5">
      <h1 class="display-1">Jukebox Tools</h1>
      <h2 class="display-4">Organize, Label, Play - The Jukebox Way</h2>
      <p class="lead">
        A user-friendly platform dedicated to helping jukebox enthusiasts
        effortlessly organize their record collections and craft custom title
        strips.
      </p>
    </div>

    <div class="m-4">
      <b-card-group deck>
        <b-card class="overflow-hidden" header="Title Strips Maker" header-tag="h3" title="Fast accurate title strips">
          <b-card-text>
            <ul>
              <li>Just enter a Discogs ID</li>
              <li>We'll pull the artist and song titles</li>
              <li>Customize how it will display on your label</li>
              <li>We'll generate a PDF of title strips to print</li>
            </ul>
          </b-card-text>
          <template #footer>
            <b-button :to="{ name: 'features' }" variant="primary">
              Learn More &raquo;
            </b-button>
          </template>
        </b-card>

        <b-card header="My Collection" header-tag="h3" title="Browse your collection">
          <b-card-text>
            <ul>
              <li>Browse, all your Records</li>
              <li>Search by Artist and Songs</li>
              <li>Edit and add to Print shop</li>
            </ul>
          </b-card-text>
          <template #footer>
            <b-button :to="{ name: 'features' }" variant="primary">
              Learn More &raquo;
            </b-button>
          </template>
        </b-card>
        <b-card header="Print Shop" header-tag="h3" title="Generate a PDF of Title strips">
          <b-card-text>
            <ul>
              <li>Save a printable PDF of your title strips to print</li>
              <li>Cut lines</li>
              <li>Prints 45s and 78s</li>
              <li>Makes a 11"x 17" page</li>
            </ul>
          </b-card-text>
          <template #footer>
            <b-button :to="{ name: 'features' }" variant="primary">
              Learn More &raquo;
            </b-button>
          </template>
        </b-card>
        <b-card header="Layout Studio" header-tag="h3" title="Plan your Jukebox layout">
          <b-card-text>
            <ul>
              <li>Drag and Drop from your collection</li>
              <li>Plan how what records you want</li>
            </ul>
          </b-card-text>
          <template #footer>
            <b-button :to="{ name: 'features' }" variant="primary">
              Learn More &raquo;
            </b-button>
          </template>
        </b-card>
      </b-card-group>
    </div>
  </div>
</template>

<script>
export default {
  name: "landing",
};
</script>

<style lang="scss" scoped>
@import "../styles.scss";
/* Import the Sass file where $accent is defined */

.hero {
  background-image: url("/images/hero.png");
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 400px;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.9);
}
</style>
